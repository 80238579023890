document.addEventListener('turbolinks:load', () => {
  const elements = document.querySelectorAll('.embed-container');
  for(let i = 0; i < elements.length; i++) {
    const el = elements[i];
    const iframe = el.querySelector('iframe');
    const loadingMessage = el.querySelector('.loading-message');
    iframe.addEventListener('load', () => {
      el.style.backgroundColor = 'transparent';
      loadingMessage.style.display = 'none';
    });
  }
})